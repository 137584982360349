<template>
    <section>
        <hr>
        <Plotly 
            :data="plotData" 
            :layout="layout"
            :options="options"
            :display-mode-bar="false"
            v-if="featureDataTer"
        />
    </section>
</template>

<script>

    // Vuex
    import { mapGetters } from 'vuex';
    // Plotly
    import { Plotly } from 'vue-plotly'

    export default {

        // Component name
        name: "chart",

        // List of components used
        components: {
            Plotly
        },

        // Data
        data () {
            return {
                layout:{
                    title: "Évolution des exploitations en Bio",
                    showlegend: false,
                    xaxis: {
                        showgrid: false,
                        zeroline: false,
                        tickangle: -45,
                        automargin: true,
                        tickmode: "linear"
                    },
                    yaxis: {
                        showline: false,
                        title: {
                            text: "S.A.U. (ha)",
                            standoff: 10
                        },
                        rangemode: "nonnegative"
                    },
                    yaxis2: {
                        title: "Nb. fermes",
                        titlefont: {color: '#e9531b'},
                        tickfont: {color: '#e9531b'},
                        overlaying: 'y',
                        side: 'right',
                        showline: false,
                        showgrid: false,
                    },
                    paper_bgcolor: "rgb(231, 231, 231);",
                    plot_bgcolor: "rgb(231, 231, 231);",
                    width: 480,
                    height: 380,
                    margin: {
                        t: 40,
                        b: 20,
                        pad: 4
                    },
                },
                options: {
                    locale: "fr"
                }
            }

        },
        computed: {
            ...mapGetters([
                "featureId",
                "layer",
                "frabData"
            ]),
            featureDataTer () {
                return this.frabData.filter(x => String(x.ter_id) == String(this.featureId));
            },
            years () {
                if (this.featureDataTer) {
                    return this.featureDataTer.map(x => parseInt(x.annee));
                }
            },
            nbFermesBio () {
                if (this.featureDataTer) {
                    return this.featureDataTer.map(x => parseInt(x.nb_ferme));
                }
            },
            sauBioYears () {
                if (this.featureDataTer) {
                    return this.featureDataTer.map(x => x.surf_bio == -1 ? null : parseInt(x.surf_bio));
                }
            },
            sauBioConvYears () {
                if (this.featureDataTer) {
                    return this.featureDataTer.map(x => x.surf_conv == -1 ? null : parseInt(x.surf_conv));
                }
            },

            plotData () {
                return [
                    {
                        name: "SAU en bio",
                        x: this.years,
                        y: this.sauBioYears,
                        type:"scatter",
                        fill: "#6fb640",
                        fillcolor: "#6fb640",
                        hoveron: 'points',
                        line: {
                            color: "#6fb640"
                        },
                        stackgroup: "two"
                    },
                    {
                        name: "SAU en conversion",                       
                        x: this.years,
                        y: this.sauBioConvYears,
                        type:"scatter",
                        fillcolor: "#1bad87",
                        fill: "#1bad87",
                        hoveron: 'points',
                        line: {
                            color: "#1bad87"
                        },
                        stackgroup: "one"
                    },
                    {
                        name: "Nb fermes",
                        x: this.years,
                        y: this.nbFermesBio,
                        type: "scatter",
                        yaxis: 'y2',
                        mode: 'lines+markers+text',
                        marker: {
                            color: "#e9531b",
                            size: 8
                        },
                        line: {
                            color: "#e9531b",
                            width: 2
                        },
                        hovertemplate: "%{y:.0f}", // Fix double tag
                        text: this.nbFermesBio,
                        textposition: "top",
                        textfont: {
                            family: "Panton",
                            size: 16,
                            color: "#e9531b"
                        },
                        cliponaxis: false // To prevent text annotation being clipped
                    }
                ]
            }
        }
    }

</script>